<template>
  <div class="SafetyDot">
    <SafetyMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <el-button size="small" plain type="primary" icon="el-icon-plus" @click="onShowAdd"
        >新建</el-button
      >
    </div>
    <!-- 数据列表 -->
    <div class="wrap">
      <el-table :data="lineList" style="width: 100%">
        <el-table-column
          prop="lineId"
          width="100"
          label="编号"
        ></el-table-column>
        <el-table-column prop="lineName" label="安检线路名称"></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              size="mini"
              plain
              icon="el-icon-edit"
              @click="onEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="onDel(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
        :page-sizes="[2, 5, 10, 20, 50, 100]"
        :total="totals"
        @current-change="getLineList"
        @size-change="getLineList"
      >
      </el-pagination>
    </div>

    <!-- 新建安检点 -->
    <el-dialog
      title="安检线路"
      :visible.sync="showLine"
      style="width: 800px; margin: 0 auto"
    >
      <el-form>
        <el-form-item>
          <el-input
            v-model="lineData.lineName"
            autocomplete="off"
            size="small"
            placeholder="安检线路名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox-group v-model="lineData.dotIds">
            <el-checkbox
              v-for="(item, index) in dotList"
              :key="index"
              :label="item.dotId"
              style="display: block;"
              >{{ item.dotName }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="showLine = false">取消</el-button>
        <el-button size="small" type="primary" @click="onSaveLine">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.SafetyDot {
  .el-form-item {
    margin-bottom: 0;
  }
  .fileIcon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .fileName {
    font-size: 14px;
    line-height: 16px;
  }
  .el-dialog__body {
    padding: 10px 20px;
  }
}
</style>

<script>
import SafetyMenu from "@/components/SafetyMenu.vue";

export default {
  name: "ProjectFile",
  components: {
    SafetyMenu,
  },
  data() {
    return {
      loading: null,
      projectList: [],
      currentProject: null,

      dotList: [],

      lineList: [],
      pageNum: 1,
      pageSize: 10,
      totals: 0,

      showLine: false,
      lineData: {
        lineId: "",
        lineName: "",
        dotIds: [],
      },
    };
  },
  computed: {},
  mounted() {
    this.getProjectList(); // 加载项目列表数据
  },
  watch: {
    currentProject: function (newVal, oldVal) {
      this.getLineList(); // 获取巡检线路列表
      this.getDotList(); // 获取巡检点位列表
    },
  },
  methods: {
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        pageNum: 1,
        pageSize: 100,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取巡检点位列表
    getDotList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: this.currentProject,
        pageNum: 1,
        pageSize: 1000,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/safetyDot/dotList",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.dotList = res.data.dotList;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取巡检线路列表
    getLineList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: this.currentProject,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/safetyLine/lineList",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.lineList = res.data.lineList;
          that.totals = res.data.totals;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 添加安检线路
    onShowAdd: function () {
      var that = this;
      this.showLine = true;
      this.lineData.lineId = ''
      this.lineData.lineName = ''
      this.lineData.dotIds = []
    },
    // 保存安检线路
    onSaveLine: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: this.currentProject,
        lineId: this.lineData.lineId,
        lineName: this.lineData.lineName,
        dotIds: this.lineData.dotIds,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/safetyLine/saveLine",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.showLine = false;
          that.getLineList()
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 编辑安检线路
    onEdit: function (row) {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: this.currentProject,
        lineId: row.lineId,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/safetyLine/queryById",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log('onEdit', res);
          that.lineData.lineId = res.data.lineId
          that.lineData.lineName = res.data.lineName
          that.lineData.dotIds = res.data.dotIds
          if (!res.data.dotIds) {
            that.lineData.dotIds = []
          }
          that.showLine = true;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 删除安检线路
    onDel: function (row) {
      var that = this;
      var data = {
        lineId: row.lineId,
      };
      this.$confirm("此操作将永久删除该线路, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.loading = that.$loading({
            lock: true,
            text: "Loading...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that
            .$axiosAdmin({
              method: "post",
              url: "api/admin/safetyLine/del",
              data: JSON.stringify(data),
            })
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.getLineList();
              that.loading.close();
            })
            .catch((err) => {
              console.log(err);
              that.loading.close();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>